@use 'sass:math';

@import 'styles/theme';

// Those values are measured from the Figma
$columnMeasuredValueMobile: 15;
$columnMeasuredValueDesktop: 60;

.ProductsGrid {
  &.hasMargins {
    margin-block: theme($spacings, 40);
    @include from('md') {
      margin-block: theme($spacings, 60);
    }
  }

  @include from('md') {
    // See https://app.clickup.com/t/2688547/NINA-2722
    & + .ProductsGrid {
      .title {
        padding-top: theme($spacings, 40);
      }
    }
  }

  .content {
    grid-column: 2 / -2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    // Measured mobile gap :
    // 10px
    @include applyColumnWidth(
      'column-gap',
      math.div(10, $columnMeasuredValueMobile),
      $mobile
    );
    row-gap: theme($spacings, 30);

    @include from('md') {
      grid-template-columns: repeat(4, 1fr);
      row-gap: theme($spacings, 60);

      // Measured desktop gap :
      // 24px
      @include applyColumnWidth(
        'column-gap',
        math.div(24, $columnMeasuredValueDesktop),
        $desktop
      );
    }
  }

  .title + .text {
    margin-top: theme($spacings, 16);
  }

  .title + .content,
  .text + .content {
    margin-top: theme($spacings, 20);

    @include from('md') {
      margin-top: theme($spacings, 25);
    }
  }

  .title {
    text-align: left;
    &.center {
      text-align: center;
    }
  }

  .title,
  .text {
    grid-column: 2 / -2;
  }

  .text {
    @include from('md') {
      grid-column: 2 / -6;
    }

    @include from('max-grid-width') {
      grid-column: 2 / 12;
    }
  }

  .cardWidth {
    // Measured mobile card width :
    // 160px
    @include applyColumnWidth(
      'width',
      math.div(160, $columnMeasuredValueMobile),
      $mobile
    );

    @include from('md') {
      // Measured desktop card width :
      // 312px
      @include applyColumnWidth(
        'width',
        math.div(312, $columnMeasuredValueDesktop),
        $desktop
      );
    }
  }
}
