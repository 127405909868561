@import 'styles/theme';

.Wysiwyg {
  text-align: center;
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .title,
  .text {
    grid-column: 3 / -3;

    @include from('md') {
      grid-column: 7 / -7;
    }
  }

  .title + .text {
    margin-top: theme($spacings, 20);
  }

  .discoverMore {
    margin-top: theme($spacings, 5);
  }
}
