@use 'sass:math';
@import 'styles/theme';

.EditoImageTitleText {
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  &.center {
    align-items: center;
  }

  &.top {
    align-items: flex-start;
  }

  &.bottom {
    align-items: flex-end;
  }

  .asset {
    grid-row: 2;
    grid-column: 2 / -2;
    height: auto;
    margin-top: theme($spacings, 40);

    @include from('md') {
      margin-top: 0;
      grid-row: 1;
      grid-column: 4 / 12;
    }
  }

  .content {
    grid-row: 1;
    grid-column: 2 / -2;

    @include from('md') {
      &.center {
        grid-column: 15 / -4;
      }

      &.centerLeft,
      &.topLeft {
        grid-column: 13 / -6;
      }

      &.centerRight,
      &.bottomRight {
        grid-column: 17 / -2;
      }
    }
    .subtitle + .title {
      margin-top: theme($spacings, 10);
    }

    .subtitle + .text,
    .title + .text {
      margin-top: theme($spacings, 20);

      @include from('md') {
        margin-top: theme($spacings, 40);
      }
    }
  }

  &.reversed {
    .asset {
      @include from('md') {
        order: 3;
        grid-column: 14 / -4;
      }
    }

    .content {
      @include from('md') {
        order: 2;
        grid-column: 4 / -15;
      }
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(480, 640));
  }
}
