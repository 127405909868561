@import 'styles/theme';

.ParcelLabTrackingWidget {
  .widgetInner {
    grid-column: 2/-2;

    .widgetInjector {
      width: 100%;
    }

    .loader {
      margin: 0 auto;
      width: theme($spacings, 30);
    }
  }
}
