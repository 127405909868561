@import 'styles/theme';

.GiftCardResult {
  .disabledText {
    color: theme($colors, 'pomegranate');
  }
  .resultLabel {
    display: flex;
    align-items: baseline;
    color: theme($colors, 'dove-gray');
  }

  .resultValue {
    color: theme($colors, 'black');
  }
}
