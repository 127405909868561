@use 'sass:math';
@import 'styles/theme';

.EditoTitleImageText {
  align-items: center;
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .title {
    grid-column: 2 / -2;
    margin-bottom: theme($spacings, 40);

    &.isBig {
      overflow-wrap: break-word;
      hyphens: auto;
    }

    @include from('md') {
      grid-column: 2 / 15;
    }
  }

  .item {
    grid-column: 2 / -2;
    height: auto;
    margin-bottom: theme($spacings, 50);

    @include from('md') {
      grid-column: 2 / 10;
      margin-bottom: 0;
    }
  }

  .content {
    grid-column: 2 / -2;

    @include from('md') {
      grid-column: 14 / -2;
    }

    .subtitle {
      & + .text {
        margin-top: theme($spacings, 20);
      }
    }

    .text {
      p + p {
        margin-top: theme($spacings, 20);

        @include from('md') {
          margin-top: theme($spacings, 30);
        }
      }
    }
  }

  &.reversed {
    .item {
      @include from('md') {
        order: 3;
        grid-column: 16 / -2;
      }
    }

    .content {
      @include from('md') {
        order: 2;
        grid-column: 2 / -14;
      }
    }
  }

  .videoRatio {
    @include applyPercentRatios(math.div(3, 4));
  }

  .imageRatio {
    @include applyPercentRatios(math.div(480, 640));
  }
}
