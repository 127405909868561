@use 'sass:math';
@import 'styles/theme';

.PushProduct {
  align-items: center;
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .image {
    grid-column: 2 / -2;
    height: auto;

    @include from('md') {
      grid-column: 3 / -12;
    }
  }

  .content {
    grid-column: 7 / -7;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include from('md') {
      grid-column: 16 / -3;
    }

    @include applyColumns(
      (
        'propertyName': 'margin-top',
        'mobile': -4,
        'desktop': 0,
      )
    );

    .linkImage {
      + .name,
      + .label {
        margin-top: theme($spacings, 20);
      }
    }

    .name {
      + .label {
        margin-top: theme($spacings, 5);
      }
    }

    .label {
      p + p {
        margin-top: theme($spacings, 5);
      }
    }

    .description {
      margin-top: theme($spacings, 10);
    }

    .link {
      display: inline-block;
      position: relative;
      margin-top: theme($spacings, 20);
      display: inline-flex;
      overflow: hidden;
    }
  }

  .imageItem {
    margin: auto;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  &.reversed {
    .image {
      @include from('md') {
        order: 2;
        grid-column: 12 / -3;
      }
    }

    .content {
      @include from('md') {
        order: 1;
        grid-column: 3 / -16;
      }
    }
  }

  .ratio {
    @include applyPercentRatios(
      math.div(330, 435),
      (
        'md': math.div(420, 560),
      )
    );
  }
}
