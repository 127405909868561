@import 'styles/theme';

.ColumnEdito {
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .leftColumn {
    grid-column: 2 / -2;
  }

  .title + .text {
    margin-top: theme($spacings, 20);
  }

  &.two-columns {
    row-gap: theme($spacings, 15);

    .leftColumn {
      @include from('md') {
        grid-column: 2 / 12;
      }
    }

    .rightColumn {
      grid-column: 2 / -2;

      @include from('md') {
        grid-column: 14 / -2;
      }
    }
  }

  &.centered {
    .leftColumn {
      @include from('md') {
        grid-column: 7 / -7;
      }
    }
  }
}
