@use 'sass:math';
@import 'styles/theme';

.ImmersiveEditoDypticImages {
  margin-top: theme($spacings, 60);
  margin-bottom: theme($spacings, 60);

  @include from('md') {
    margin-top: theme($spacings, 100);
    margin-bottom: theme($spacings, 100);
  }

  .imageContainer {
    grid-column: 1/-1;

    &.isLeftImage {
      display: flex;
      background: theme($colors, 'black');

      .image {
        margin: theme($spacings, 120) auto;
        @include applyColumnWidth('width', 16, $mobile);
      }
    }

    @include from('md') {
      grid-column: span 12;

      &.isLeftImage {
        .image {
          margin: 0;
          width: 100%;
        }
      }
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(720, 1000));
  }
}
