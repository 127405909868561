@import 'styles/theme';

.HeroBottomText {
  width: 100%;
  position: relative;
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  margin-top: theme($spacings, 20);

  .title {
    // Fix for overflow hidden overlap
    &:not(:first-of-type) {
      position: absolute;
      width: 100%;
      left: 0;
    }

    &.theme-black {
      color: theme($colors, 'black');
    }

    &.theme-white {
      color: theme($colors, 'white');
    }
  }
}

.textsContainer {
  position: relative;
  grid-column: 2 / -2;

  .text {
    width: 100%;

    &:not(:first-of-type) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
