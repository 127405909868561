@import 'styles/theme';

.Announcement {
  overflow: hidden;
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .text {
    grid-column: 2 / -2;

    text-align: center;

    @include from('md') {
      grid-column: 3 / -3;
    }
  }
}
