@use 'sass:math';
@import 'styles/theme';

$banner-height-fix: 8px;

.HeroMedia {
  overflow: hidden;
  width: 100%;
  @include switchDesktopMobileClasses('md');

  &.isImage {
    height: 500px;

    @include from('md') {
      height: stableVh(80);
    }
  }

  .ratio {
    padding-bottom: getPercentRatio(9/16);

    @include from('md') {
      padding-bottom: getPercentRatio(16/9);
    }
  }

  .item {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;

    @include applyColumns(
      (
        'propertyName': 'width',
        'desktop': 24,
        'mobile': 24,
      )
    );
  }

  .itemLink {
    display: block;
    height: 100%;
    width: 100%;
  }

  .image {
    height: 100%;

    &.imageMobile {
      @include from('md') {
        display: none;
      }
    }

    &.hasCoverMobile {
      display: none;

      @include from('md') {
        display: block;
      }
    }
  }

  .video {
    position: relative;

    .videoPlayerContainer {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }

    .videoPlayer {
      height: 100%;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-in;

      &.hide {
        opacity: 0;
      }
    }
  }
}
