@use 'sass:math';

@import 'styles/theme';

// Those values are measured from the Figma
$columnMeasuredValueMobile: 15;
$columnMeasuredValueDesktop: 60;

.CategoryGridEdito {
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .title {
    grid-column: 2 / -2;
  }

  .content {
    overflow: hidden;
  }

  .slider {
    margin-top: theme($spacings, 25);
    grid-column: 2 / -2;
    width: 100%;
  }

  .card {
    // Measured card width :
    // Mobile : 160px
    // Desktop : 312px
    @include applyColumns(
      (
        'propertyName': 'width',
        'desktop': math.div(312, $columnMeasuredValueDesktop),
        'mobile': math.div(160, $columnMeasuredValueMobile),
      )
    );

    &:not(:last-child) {
      @include applyColumns(
        (
          'propertyName': 'margin-right',
          'desktop': math.div(24, $columnMeasuredValueDesktop),
          'mobile': math.div(10, $columnMeasuredValueMobile),
        )
      );
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
