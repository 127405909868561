@import 'styles/theme';

.HeroMain {
  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .media {
    position: relative;
    grid-column: 1 / -1;
    line-height: 0;
  }

  .heroText {
    grid-column: 2/-2;
  }

  &.titleLayout {
    &-title-on-image {
      .media,
      .heroText {
        grid-row: 1;
      }

      .heroText {
        margin-top: auto;
        padding-bottom: theme($spacings, 20);
      }
    }

    &-title-below-image-edito-right {
      .bottomContainer {
        grid-column: 2 / -2;

        @include from('md') {
          display: flex;
          flex-direction: row;
          gap: getGridColumnWidth(1, $desktop);
        }
      }
    }
  }

  &.textAlign-align-left {
    text-align: left;

    .textsContainer {
      grid-column: 2 / -2;
      @include from('md') {
        grid-column: 2 / 12;
      }
    }
  }

  &.textAlign-align-center {
    text-align: center;

    .textsContainer {
      grid-column: 2 / -2;
      @include from('md') {
        grid-column: 9 / -9;
      }
    }
  }

  &.textAlign-align-right {
    text-align: right;

    .textsContainer {
      grid-column: 2 / -2;

      @include from('md') {
        grid-column: 15 / -2;
      }
    }
  }
}
