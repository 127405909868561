@import 'styles/theme';

.HeroOneLinerText {
  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .title {
    grid-column: 2/-2;
  }

  * + .subtitle {
    margin-top: theme($spacings, 10);
    grid-column: 2/-2;

    @include from('md') {
      margin-top: theme($spacings, 14);
    }
  }

  .title,
  .subtitle {
    &.isCentered {
      text-align: center;
    }
    &.isLeft {
      text-align: left;
    }
    &.isRight {
      text-align: right;
    }
  }
}
