@import 'styles/theme';

.GiftCardBalanceChecker {
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .left,
  .right {
    grid-column: 2/-2;
  }

  .left {
    margin-bottom: theme($spacings, 24);

    .title {
      @include from('md') {
        margin-top: theme($spacings, 60);
      }
    }

    .subtitle {
      color: theme($colors, 'dove-gray');
      margin-top: theme($spacings, 24);
    }
  }

  .result {
    margin-top: theme($spacings, 20);
  }

  .checker {
    margin-top: theme($spacings, 12);

    .input {
      &::placeholder {
        text-transform: uppercase;
        color: theme($colors, 'dove-gray');
      }
    }
    .cta {
      width: 100%;
      margin-top: theme($spacings, 12);
    }
  }

  @include from('md') {
    margin-top: theme($spacings, 40);
    margin-bottom: theme($spacings, 40);

    .left {
      grid-column: 2/9;
      margin-bottom: 0;
    }
    .right {
      grid-column: 12/-2;
    }
  }
}
