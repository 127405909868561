@import 'styles/theme';

.notDragging {
  cursor: grab;
}

.isDragging {
  cursor: grabbing;
}

.SliderRangeSteps {
  position: relative;
  padding: 10px 0;
  width: 250px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 1px;
    width: 100%;
    background: black;
  }

  .cursorContainer {
    margin: -8px;
    pointer-events: auto;
    position: absolute;
    z-index: 2;
  }

  .cursor {
    width: 16px;
    height: 16px;
    background: black;
    overflow: hidden;
    border-radius: 50%;
  }
}
