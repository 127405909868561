@use 'sass:math';
@import 'styles/theme';

.EditoPush {
  overflow: hidden;

  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .wrapper {
    grid-column: 2 / -1;
    overflow: hidden;

    @include from('md') {
      grid-column: 2 / -2;
    }
  }

  .desktopList {
    display: none;
    @include from('md') {
      display: block;

      display: flex;
      flex-direction: row;
      gap: 24px;
    }

    .item {
      width: calc(100% / 3);
    }
  }

  .mobileSlider {
    display: block;
    width: 100%;

    @include from('md') {
      display: none;
    }

    .sliderContainer {
      gap: getGridColumnWidth(1, $mobile);
    }

    .item {
      width: getGridColumnWidth(18, $mobile);
    }
  }
}
