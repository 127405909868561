@use 'sass:math';
@import 'styles/theme';

.Video {
  position: relative;
  display: block;

  .videoPlayerMock {
    z-index: 3;
    position: absolute;
    grid-column: 1 / -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .playPauseLayer {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .videoControls {
    z-index: 2;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0 theme($spacings, 10) theme($spacings, 10) theme($spacings, 10);
    box-sizing: border-box;

    @include from('md') {
      padding: 0 theme($spacings, 15) theme($spacings, 15) theme($spacings, 15);
    }
  }

  .videoContainer {
    pointer-events: none;
    z-index: 4;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    transition: opacity 0.4s ease-in-out;
    padding-bottom: theme($spacings, 30);
    padding-top: theme($spacings, 30);

    @include applyColumns(
      (
        'propertyName': 'padding-left',
        'desktop': 1,
        'mobile': 1,
      )
    );

    @include applyColumns(
      (
        'propertyName': 'padding-right',
        'desktop': 1,
        'mobile': 1,
      )
    );

    @include from('md') {
      padding-bottom: theme($spacings, 40);
      padding-top: theme($spacings, 40);
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }

    &.smallPadding {
      padding-block: theme($spacings, 20);
      padding-inline: theme($spacings, 30);
    }

    .name {
      margin-bottom: theme($spacings, 10);
    }

    .duration {
      transition: opacity 0.3s ease-in;

      &.hide {
        opacity: 0;
      }
    }
  }

  .playRoundedIcon {
    width: theme($spacings, 40);
    height: theme($spacings, 40);

    @include from('md') {
      width: theme($spacings, 60);
      height: theme($spacings, 60);
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(16, 9));
  }
}
