@import 'styles/theme';

.ShopifyQuizz {
  font-family: theme($fontFamilies, 'Haffer');

  &.isFirstSlice {
    padding-top: theme($navHeight, 'mobile');

    @include from('md') {
      padding-top: theme($navHeight, 'desktop');
    }
  }

  [data-quiz-kit-watermark] {
    display: none;
  }
}

#quiz-kit-app-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 700px;

  .quiz-kit-app-boxes {
    display: flex;
    margin-top: 50px;
  }

  .quiz-kit-app-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px;
  }

  .quiz-kit-app-line {
    position: relative;
    width: 200px;
    max-width: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: #f2f2f2;
    margin-bottom: 10px;
  }

  .quiz-kit-app-line-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #f2f2f2;
    background-image: linear-gradient(90deg, #f2f2f2, #f6f6f6, #f2f2f2);
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    animation: animation-loading-line 1.2s ease-in-out infinite;
  }

  .quiz-kit-app-line-loading--dark {
    background-color: #d9d9d9;
    background-image: linear-gradient(90deg, #d9d9d9, #e8e8e8, #d9d9d9);
  }

  .quiz-kit-app-line--long {
    width: 400px;
    background-color: #d9d9d9;
  }

  .quiz-kit-app-line--tall {
    width: 256px;
    height: 256px;
    margin-bottom: 20px;
  }

  .quiz-kit-app-line--dark {
    background-color: #d9d9d9;
  }

  .quiz-kit-app-line--small {
    width: 100px;
  }

  @media screen and (max-width: 1300px) {
    .quiz-kit-app-box:nth-child(3),
    .quiz-kit-app-box:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    #quiz-kit-app-wrapper {
      min-height: 350px;
    }

    .quiz-kit-app-box:nth-child(2) {
      display: none;
    }

    .quiz-kit-app-line {
      max-width: 335px;
    }

    .quiz-kit-app-line--tall {
      height: 156px;
    }

    .quiz-kit-app-boxes {
      margin-top: 30px;
    }
  }
}

@keyframes animation-loading-line {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}
