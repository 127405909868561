@use 'sass:math';
@import 'styles/theme';

.HeaderStory {
  text-align: center;
  width: 100%;
  overflow: hidden;

  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .title {
    position: relative;
    z-index: 2;
    grid-column: 2 / -2;
    word-break: break-word;
    margin-bottom: theme($spacings, 30);

    @include from('md') {
      text-align: center;
      grid-column: 4 / -4;
    }

    & + .subtitle {
      margin-top: theme($spacings, 10);
    }
  }

  .subtitle {
    position: relative;
    z-index: 2;
    grid-column: 6 / -6;

    @include from('md') {
      grid-column: 4 / -4;
    }

    margin-bottom: theme($spacings, 30);

    &.subtitleWithCta {
      margin-bottom: theme($spacings, 20);
    }
  }

  .cta {
    margin-bottom: theme($spacings, 60);
    grid-column: 6 / -6;

    @include from('md') {
      grid-column: 4 / -4;
    }
  }

  .imageContainer {
    position: relative;
    z-index: 1;
    grid-column: 2 / -2;
    overflow: hidden;

    @include from('md') {
      grid-column: 6 / -6;
    }
  }

  // Variations
  &.isFullWidth {
    .title {
      grid-column: 2/-2;
    }
  }

  &.hasSubtitle {
    .title {
      margin-bottom: 0;
    }
  }

  &.hasImage {
    margin-bottom: theme($spacings, 60);

    @include from('md') {
      margin-bottom: theme($spacings, 120);
    }
  }

  .ratio {
    &.portrait {
      @include applyPercentRatios(math.div(840, 1120));
    }

    &.landscape {
      @include applyPercentRatios(math.div(1440, 900));
    }

    &.square {
      @include applyPercentRatios(math.div(1, 1));
    }
  }
}
