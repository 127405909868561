@import 'styles/theme';

.PushImageCover {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;

  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .image {
    grid-column: 4 / -4;
    grid-row: 1 / 2;

    @include from('md') {
      grid-column: 9 / -9;
    }
  }

  .title {
    position: relative;
    grid-column: 2 / -2;
    text-align: center;

    &.top {
      margin-top: theme($spacings, 20);
    }
  }

  .cta {
    grid-column: 9 / -9;
    margin-top: theme($spacings, 30);

    @include from('md') {
      grid-column: 12 / -12;
    }
  }

  .ratio {
    @include applyPercentRatios(1);
  }
}
