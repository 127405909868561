@use 'sass:math';
@import 'styles/theme';

.ImmersiveEditoHero {
  position: relative;
  background: theme($colors, 'black');
  padding-block: theme($navHeight, 'mobile');

  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  @include from('md') {
    background: transparent;
    padding-top: theme($navHeight, 'desktop');

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      max-height: stableVh(100);
      top: 0;
      left: 0;
      width: 100%;
      background: theme($colors, 'black');
    }
  }

  .top {
    position: relative;
    grid-column: 2/-2;
    text-align: center;
    margin-bottom: theme($spacings, 50);

    @include from('md') {
      grid-column: 9/-9;
      margin-bottom: theme($spacings, 60);
    }
  }

  .title,
  .subtitle {
    @include fontSmoothed();
  }

  .subtitle {
    margin-top: theme($spacings, 10);
  }

  .asset {
    grid-column: 2/-2;
    @include applyColumnWidth('margin-bottom', 1, $mobile);

    @include from('md') {
      grid-column: 6/-6;
      margin-bottom: 0;
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(840, 1000));
  }
}
