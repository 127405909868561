@import 'styles/theme';

.GridLookbookLinks {
  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .content {
    grid-column: 2 / -2;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .link {
    display: block;
    margin-top: theme($spacings, 20);
    line-height: 0.87;
  }
}
