@use 'sass:math';
@import 'styles/theme';

.PushImage {
  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 50);

  @include from('md') {
    margin-top: theme($spacings, 60);
    margin-bottom: theme($spacings, 60);
  }

  &.reversed {
    .left {
      grid-column: 4 / -4;

      @include applyColumns(
        (
          'propertyName': 'padding-left',
          'mobile': 0,
          'desktop': 0.5,
        )
      );

      @include applyColumns(
        (
          'propertyName': 'padding-right',
          'mobile': 0,
          'desktop': 0.5,
        )
      );

      @include from('md') {
        grid-column: 3 / 11;
      }

      .text {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .right {
      grid-column: 2 / -2;
      padding-left: 0;
      padding-right: 0;

      @include from('md') {
        grid-column: 13 / -2;
      }
    }
  }

  .texts {
    margin-top: theme($spacings, 10);

    @include from('md') {
      margin-top: theme($spacings, 12);
    }
  }

  .name {
    + .label {
      margin-top: theme($spacings, 10);
    }
  }

  .image {
    height: auto;
  }

  .left {
    grid-column: 2 / -2;

    @include from('md') {
      grid-row: 1;
      grid-column: 2 / 13;
    }
  }

  .right {
    grid-column: 4 / -4;
    margin-top: theme($spacings, 50);

    @include applyColumns(
      (
        'propertyName': 'padding-left',
        'mobile': 0,
        'desktop': 0.5,
      )
    );

    @include applyColumns(
      (
        'propertyName': 'padding-right',
        'mobile': 0,
        'desktop': 0.5,
      )
    );

    @include from('md') {
      margin-top: 0;
      grid-row: 1;
      grid-column: 15 / -3;
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(480, 640));
  }
}
