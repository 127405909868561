@import 'styles/theme';

.ImmersiveEditoImagesSlider {
  width: 100%;
  overflow: hidden;

  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .containerSlider {
    width: 100%;
    overflow: hidden;
  }

  .titleContainer {
    margin-bottom: theme($spacings, 20);
  }

  .ctaContainer {
    margin-top: theme($spacings, 20);
  }

  .title,
  .cta {
    grid-column: 2 /-2;
  }

  .slider {
    width: 100%;

    .image {
      margin-right: theme($spacings, 10);

      @include from('md') {
        margin-right: theme($spacings, 25);
      }

      &.portrait {
        @include applyColumns(
          (
            'propertyName': 'width',
            'desktop': 5,
            'mobile': 12,
          )
        );
      }

      &.landscape {
        @include applyColumns(
          (
            'propertyName': 'width',
            'desktop': 7.5,
            'mobile': 18,
          )
        );
      }
    }
  }
}
