@import 'styles/theme';

.ImmersiveEditoHeroTeasing {
  position: relative;
  width: 100%;

  @include switchDesktopMobileClasses('md');

  .imageContainer {
    position: relative;
    width: 100%;
    height: stableVh(100);
    max-height: stableVh(90);

    @include from('md') {
      max-height: unset;
    }
  }

  .imageContent,
  .imageContentAnimated {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .image {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.2s opacity;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  .repeatTextsContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: stableVh(100);
    z-index: 2;

    @include from('md') {
      height: stableVh(50);
    }
  }

  .repeatTexts {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    top: theme($spacings, 10);

    .repeatSmallItem {
      margin-right: theme($spacings, 60);
    }
  }

  &.isBottom {
    .repeatTextsContainer {
      top: auto;
      bottom: 0;
    }

    .repeatTexts {
      top: auto;
      bottom: theme($spacings, 10);
    }
  }
}
