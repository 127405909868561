@use 'sass:math';
@import 'styles/theme';

.PushProductsImages {
  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .title,
  .description {
    grid-column: 2 / -2;

    &.center {
      @include from('md') {
        grid-column: 7 / -7;
      }
    }

    &.left {
      @include from('md') {
        grid-column: 2 / -10;
      }
    }

    &.right {
      @include from('md') {
        grid-column: 10 / -2;
      }
    }
  }

  .title {
    text-transform: uppercase;

    + .description {
      margin-top: theme($spacings, 10);

      @include from('md') {
        margin-top: theme($spacings, 20);
      }
    }

    & + .container {
      margin-top: theme($spacings, 20);
    }
  }

  .description {
    + .container {
      margin-top: theme($spacings, 20);
    }
  }

  .imageContainer {
    overflow: hidden;
  }

  .container {
    grid-column: 1 / -1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include from('md') {
      justify-content: space-between;
      grid-column: 2 / -2;
    }

    .asset {
      height: auto;

      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 14.5,
          'mobile': 24,
        )
      );
    }

    .card {
      margin-top: theme($spacings, 20);

      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 7,
          'mobile': 22,
        )
      );

      @include from('md') {
        margin-top: 0;
      }
    }
  }

  &.reversed {
    .container {
      flex-direction: column-reverse;
      align-items: center;

      @include from('md') {
        flex-direction: row-reverse;
        align-items: initial;
      }

      .card {
        margin-top: 0;
        margin-bottom: theme($spacings, 20);
      }
    }
  }

  .assetRatio {
    @include applyPercentRatios(
      math.div(360, 530),
      (
        'md': math.div(870, 640),
      )
    );
  }

  .cardRatio {
    @include applyPercentRatios(
      math.div(330, 435),
      (
        'md': math.div(420, 560),
      )
    );
  }
}
