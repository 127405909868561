@use 'sass:math';
@import 'styles/theme';

.GridLookbookImages {
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .content {
    grid-column: 2 / -2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .item {
    @include applyColumns(
      (
        'propertyName': 'width',
        'desktop': 10.5,
        'mobile': 10.5,
      )
    );

    .asset {
      height: auto;
    }

    .zoom {
      cursor: pointer;
    }

    .text {
      margin-top: theme($spacings, 10);

      @include from('md') {
        margin-top: theme($spacings, 20);
      }
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(630, 840));
  }
}
