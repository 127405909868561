@import 'styles/theme';

.ImageGallery {
  position: relative;
  top: 0;
  left: 0;
  width: vw(100);
  z-index: 30;
  background-color: theme($colors, 'white');

  .slider {
    position: relative;
    width: vw(100);
  }

  .imageContainer {
    position: relative;
    width: vw(100);
    height: 100vh;
  }

  .image {
    height: 100%;
  }

  .closeButton {
    position: fixed;
    top: 25px;
    right: 25px;
  }
}
