@use 'sass:math';
@import 'styles/theme';
.DiptychOrTriptychItem {
  position: relative;

  .ratio {
    @include applyPercentRatios(math.div(360, 500));
    @include from('md') {
      @include applyPercentRatios(math.div(720, 900));
    }
  }

  .content {
    z-index: 5;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: calc(100% - getGridColumnWidth(1, $mobile));
    @include from('md') {
      width: calc(100% - getGridColumnWidth(1, $desktop));
    }

    &.mobileColorDark {
      color: theme($colors, 'black');
    }
    &.mobileColorLight {
      color: theme($colors, 'white');
    }

    @include from('md') {
      &.desktopColorDark {
        color: theme($colors, 'black');
      }
      &.desktopColorLight {
        color: theme($colors, 'white');
      }
    }
  }

  .cta {
    color: inherit;
  }

  * + .cta {
    margin-top: theme($spacings, 12);

    @include from('md') {
      margin-top: theme($spacings, 14);
    }
  }
}
