@import 'styles/theme';

.SliderNavigation {
  display: flex;
  align-items: center;

  .button {
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    padding: theme($spacings, 5) theme($spacings, 10);

    &.isVisible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .icon {
    width: 3px;
    height: 5px;
  }
}
