@import 'styles/theme';

.HeroMainTeasing {
  position: relative;
  width: 100%;

  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .glBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .topContainer {
    position: relative;
    width: 100%;
    height: stableVh(90);
    overflow: hidden;
    cursor: pointer;

    @include from('md') {
      height: vh(100);
      &.isFirstSlice {
        height: calc(vh(100) - var(--banner-height));
      }
    }
  }

  .title {
    grid-column: 2 / -2;
    width: 100%;
    display: block;
    margin-top: theme($spacings, 25);
  }

  .videoPlayer {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.2s opacity;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  .links {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    &.top {
      bottom: theme($spacings, 15);
    }

    &.bottom {
      top: theme($spacings, 20);
      &.isFirstSlice {
        top: theme($spacings, 80);
        @include from('md') {
          top: theme($spacings, 100);
        }
      }
    }

    .linksContainer {
      grid-column: 2 / -2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .repeatTexts {
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;

    &.top {
      top: theme($spacings, 20);
    }

    &.isFirstSlice {
      &.top {
        top: theme($spacings, 80);
      }
    }

    &.bottom {
      bottom: theme($spacings, 10);
    }

    .repeatSmallItem {
      margin-right: theme($spacings, 60);
    }

    .repeatText {
      &:last-of-type {
        font-weight: 700;
      }
    }
  }

  .bottomFormContainer {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .background {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .bottomForm {
    background-color: white;
    position: relative;
    padding: theme($spacings, 40) 0 theme($spacings, 50);
    width: 100%;
    overflow: hidden;
    min-height: stableVh(50);
    display: flex;
    align-items: center;
    overflow: auto;
    box-sizing: border-box;
  }

  .marker {
    visibility: hidden;
    position: absolute;
    top: calc(vh(100) + 30px);
    width: 100%;
    height: 1px;
  }

  .closeIcon {
    position: absolute;
    width: 15px;
    cursor: pointer;
    top: 15px;
    right: 15px;

    @include from('md') {
      top: 20px;
      right: 20px;
    }
  }
}
