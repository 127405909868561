@use 'sass:math';
@import 'styles/theme';

.DiptychOrTriptych {
  display: flex;
  flex-direction: column;

  margin-block: theme($spacings, 40);

  @include from('md') {
    flex-direction: row;
    margin-block: theme($spacings, 60);
  }
  .item {
    width: 100%;
    @include from('md') {
      &.diptych {
        width: calc(100% / 2);
      }
      &.triptych {
        width: calc(100% / 3);
      }
    }
  }
}
