@use 'sass:math';
@import 'styles/theme';

.VideoPlayer {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;

  line-height: 0;

  .wrapper {
    position: relative;
    width: 100%;
  }

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .videoInner {
    height: 100%;
  }

  .fullScreenControl {
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(16, 9));
  }

  .isFullScreen {
    top: 50%;
    transform: translateY(-50%);
  }

  &.tutoFaqTheme {
    .controlsWrapper {
      background: transparent;
      bottom: auto;
      @include applyColumnWidth('top', 1, $mobile);

      @include from('md') {
        @include applyColumnWidth('top', 1, $desktop);
      }
    }

    .iconsControls {
      border-right: none;

      .playPauseIcon {
        padding-left: 0;
        border-right: none;

        path {
          fill: theme($colors, 'white');
        }
      }

      .muteUnmuteIcon {
        display: none;
      }
    }

    .video {
      & > div {
        padding: 0;
        position: relative;

        & > iframe {
          box-sizing: border-box;
          // SORRY I HAD NO CHOICE.
          // BLAME SIMON ON THIS ONE.
          // CF: https://gist.github.com/BarryMode/7594871a43775dc70f92af5ad45a2774
          width: 177.77777778vh !important;
          height: 56.25vw !important;
          // END OF SORRY
          min-width: 100%;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .videoProgressWrapper {
      margin: 0;
      padding: 0;
    }

    .videoProgressContent {
      height: 1px;
    }
  }
}
