@use 'sass:math';
@import 'styles/theme';

.EditoColumn {
  grid-auto-rows: auto;
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  &.reversed {
    .hideOnSmallScreen {
      grid-column: 1 / 23;
      .image {
        order: 1;
      }

      .component {
        order: 2;
      }
    }
  }

  .hideOnSmallScreen {
    grid-column: 3 / 25;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .image {
      @include applyColumnWidth('width', 12, $desktop);
    }

    .component {
      @include applyColumnWidth('width', 7, $desktop);
    }
  }

  .hideOnLargeScreen {
    grid-column: 2 / -2;
  }

  @include switchDesktopMobileClasses('md', flex);

  .header {
    .title {
      + .intensity {
        margin-top: theme($spacings, 10);
      }
    }

    .title,
    .intensity {
      + .description {
        margin-top: theme($spacings, 20);
      }
    }

    @include from('md') {
      margin-top: theme($spacings, 60);
    }
  }

  .image {
    height: auto;
    margin-top: theme($spacings, 30);

    @include from('md') {
      margin-top: 0;
    }
  }

  .footer {
    position: relative;
    z-index: 2;

    .subtitle {
      margin-top: theme($spacings, 30);

      @include from('md') {
        margin-top: theme($spacings, 40);
      }
    }

    .texts {
      margin-top: theme($spacings, 30);

      .label {
        + .text {
          margin-top: theme($spacings, 10);
        }
      }

      .text {
        + .label {
          margin-top: theme($spacings, 30);
        }
      }
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(720, 800));
  }
}
