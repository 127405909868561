@use 'sass:math';
@import 'styles/theme';

.GridLookbookSingleImage {
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .image,
  .text {
    grid-column: 2 / -2;

    @include from('md') {
      grid-column: 6 / -6;
    }
  }

  .image {
    grid-row: 1;
    cursor: pointer;
  }

  .text {
    grid-row: 2;

    margin-top: theme($spacings, 10);

    @include from('md') {
      margin-top: theme($spacings, 20);
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(840, 1120));
  }
}
