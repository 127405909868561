@use 'sass:math';
@import 'styles/theme';

.EditoDypticImage {
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .item {
    height: auto;

    &.video {
      display: flex;
      flex-direction: column;
    }

    &.big {
      grid-column: 2 / -8;
      order: 1;

      @include from('md') {
        margin-top: theme($spacings, 120);
        grid-column: 13 / -3;
        order: 2;
      }
    }

    &.small {
      grid-column: 11 / -2;
      order: 2;
      margin-top: theme($spacings, 40);

      @include from('md') {
        margin-top: 0;
        grid-column: 3 / 9;
        order: 1;
      }
    }
  }

  &.reversed {
    .item {
      &.big {
        grid-column: -2 / 8;

        @include from('md') {
          order: 1;
          grid-column: -13 / 3;
        }
      }

      &.small {
        grid-column: -11 / 2;

        @include from('md') {
          grid-column: -3 / -9;
          order: 2;
        }
      }
    }
  }

  .video {
    .videoPlayer {
      bottom: auto;
    }

    .videoMock {
      height: auto;
    }
  }

  .videoRatio {
    @include applyPercentRatios(math.div(3, 4));
  }

  .imageSmallRatio {
    @include applyPercentRatios(
      math.div(240, 320),
      (
        'md': math.div(600, 800),
      )
    );
  }

  .imageBigRatio {
    @include applyPercentRatios(
      math.div(195, 260),
      (
        'md': math.div(360, 480),
      )
    );
  }
}
