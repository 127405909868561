@use 'sass:math';
@import 'styles/theme';

.PushProductsSlider {
  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 50);

  @include from('md') {
    margin-top: theme($spacings, 60);
    margin-bottom: theme($spacings, 60);
  }

  .image {
    grid-column: 1 / -1;

    @include from('md') {
      grid-column: 1 / 13;
    }

    &.reversed {
      grid-column: 1 / -1;
      @include from('md') {
        grid-column: -13 / -1;
      }
    }
  }

  .content {
    grid-column: 2 / -2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: theme($spacings, 45);
    margin-bottom: theme($spacings, 25);

    @include from('md') {
      align-self: center;
      grid-column: -13 / -1;
      margin-top: theme($spacings, 60);
      margin-bottom: theme($spacings, 50);
    }

    &.reversed {
      grid-column: 2 / -2;
      @include from('md') {
        grid-row: 1;
        grid-column: 1 / 13;
      }
    }

    .productSlider,
    .productImage {
      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 7,
          'mobile': 19,
        )
      );
    }

    .nameSlider,
    .nameRichtext {
      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 12,
          'mobile': 24,
        )
      );
    }

    .navigation {
      margin-top: theme($spacings, 30);

      @include from('md') {
        margin-top: theme($spacings, 10);
      }
    }

    .nameSlider {
      margin-top: theme($spacings, 20);
    }

    .nameRichtext {
      text-align: center;
    }

    .text {
      text-align: center;
      margin-top: theme($spacings, 10);
      margin-left: auto;
      margin-right: auto;

      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 6,
          'mobile': 20,
        )
      );

      @include from('md') {
        margin-top: 0;
      }
    }

    .buttons {
      position: relative;
      margin-top: theme($spacings, 20);
    }

    .button {
      pointer-events: none;

      &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
      }

      &.isActive {
        pointer-events: auto;
      }
    }
  }

  .item {
    @include applyColumns(
      (
        'propertyName': 'width',
        'desktop': 7,
        'mobile': 19,
      )
    );
  }

  .navigationArrows {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: calc(100% + getGridColumnWidth(2, $mobile));
    justify-content: space-between;
    pointer-events: none;
    box-sizing: border-box;

    @include from('md') {
      width: calc(100% + getGridColumnWidth(1, $desktop));
    }

    & > * {
      pointer-events: auto;
    }
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
  }

  .contentImage {
    position: relative;
  }

  .bigRatio {
    @include applyPercentRatios(
      math.div(350, 530),
      (
        'md': math.div(720, 885),
      )
    );
  }

  .smallRatio {
    @include applyPercentRatios(
      math.div(330, 435),
      (
        'md': math.div(420, 560),
      )
    );
  }
}
