@import 'styles/theme';
.HeroMainText {
  .cta {
    display: inline-block;
  }

  .ctaLabel {
    text-transform: uppercase;
  }

  .subtitle,
  .description,
  .discoverMore,
  .cta {
    margin-top: theme($spacings, 16);
  }
}
