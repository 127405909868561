@use 'sass:math';
@import 'styles/theme';

.MasonryImage {
  overflow: hidden;
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .title {
    grid-column: 2 / -2;
    text-align: center;
    z-index: 2;
    margin-bottom: theme($spacings, 30);

    &.bottom {
      position: relative;
      margin-top: theme($spacings, 30);
      margin-bottom: 0;
    }

    @include from('md') {
      margin-bottom: theme($spacings, 60);

      &.bottom {
        margin-top: theme($spacings, 60);
      }
    }
  }

  .slider {
    grid-column: 6 / -6;
    width: 100%;

    @include from('md') {
      display: none;
    }

    > div {
      align-items: center;
    }
  }

  .imagesContainer {
    display: none;
    grid-column: 2 / -2;

    @include from('md') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .imagesChunk {
      .image + .image {
        @include applyColumnWidth('margin-top', 0.5, $desktop);
      }
    }
  }

  .image {
    will-change: transform;
    @include applyColumns(
      (
        'propertyName': 'width',
        'desktop': 7,
        'mobile': 14,
      )
    );

    @include applyColumns(
      (
        'propertyName': 'margin-right',
        'desktop': 0,
        'mobile': 1,
      )
    );

    @include from('md') {
      height: auto;
    }
  }

  .title {
  }

  .evenRatio {
    @include applyPercentRatios(
      math.div(210, 280),
      (
        'md': math.div(420, 420),
      )
    );
  }

  .oddRatio {
    @include applyPercentRatios(
      math.div(210, 340),
      (
        'md': math.div(420, 420),
      )
    );
  }

  .bigRatio {
    @include applyPercentRatios(
      math.div(210, 340),
      (
        'md': math.div(420, 635),
      )
    );
  }
}
