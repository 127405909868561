@use 'sass:math';
@import 'styles/theme';

.EditoPushItem {
  .wrapper {
    position: relative;
  }

  .diptych {
    .ratio {
      @include applyPercentRatios(math.div(330, 410));

      @include from('md') {
        @include applyPercentRatios(math.div(648, 810));
      }
    }
  }

  .triptych {
    .ratio {
      @include applyPercentRatios(math.div(270, 380));

      @include from('md') {
        @include applyPercentRatios(math.div(424, 600));
      }
    }
  }

  .quadriptych {
    .ratio {
      @include applyPercentRatios(math.div(270, 380));

      @include from('md') {
        @include applyPercentRatios(math.div(312, 445));
      }
    }
  }

  .content {
    text-align: center;
    width: calc(100% - getGridColumnWidth(1, $desktop));

    &.inside {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      padding-block: 24px;
    }

    &.outside {
      position: relative;
      margin-top: theme($spacings, 24);
      margin: theme($spacings, 24) auto 0 auto;
    }

    &.mobileColorDark {
      color: theme($colors, 'black');
    }
    &.mobileColorLight {
      color: theme($colors, 'white');
    }

    @include from('md') {
      &.desktopColorDark {
        color: theme($colors, 'black');
      }
      &.desktopColorLight {
        color: theme($colors, 'white');
      }
    }
  }

  .cta {
    color: inherit;
  }

  * + .cta {
    margin-top: theme($spacings, 8);
  }
}
