@use 'sass:math';
@import 'styles/theme';

.EditoImage {
  align-items: center;
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .image {
    grid-column: 2 / -2;
    margin-bottom: theme($spacings, 40);
    height: auto;

    @include from('md') {
      grid-column: 2 / -13;
      margin-bottom: 0;
    }
  }

  .content {
    grid-column: 2 / -2;

    @include from('md') {
      grid-column: -11 / -3;
    }
  }

  .title + .text {
    margin-top: theme($spacings, 20);
  }

  &.reversed {
    .image {
      @include from('md') {
        order: 2;
        grid-column: 13 / -2;
      }
    }

    .content {
      @include from('md') {
        order: 1;
        grid-column: 3 / -15;
      }
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(660, 800));
  }
}
