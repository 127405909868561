@import 'styles/theme';

.FullScreenImage {
  cursor: zoom-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 30;

  .controlsContainer {
    z-index: 1;
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    // Scrollbar size
    padding-right: 15px;
  }

  .controls {
    grid-column: 2 / -2;
    text-align: right;

    button {
      padding: theme($spacings, 10);
      // Negative margin to keep alignments
      margin-right: calc(-1 * #{theme($spacings, 10)});
      margin-left: theme($spacings, 10);
      margin-top: calc(-1 * #{theme($spacings, 10)});
      cursor: pointer;
    }
  }

  .fullScreenImageContainer {
    position: absolute;
  }

  .placeholder {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
