@use 'sass:math';
@import 'styles/theme';

@function fixIssueSafariDecimalValue($value) {
  @return calc($value - 0.1px);
}

.GridLookbookSliderMultipleLayout {
  overflow: hidden;

  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }
  .topContent {
    position: relative;
    grid-row: 1;
    grid-column: 2 / -2;
    margin-bottom: theme($spacings, 20);
    display: flex;
    flex-direction: column;

    @include from('md') {
      flex-direction: row;
      margin-bottom: theme($spacings, 40);
    }
  }

  .SliderRangeSteps {
    @include applyColumnWidth('width', 20, $mobile);
    margin-left: auto;
    margin-right: auto;
    margin-top: theme($spacings, 25);

    &:after {
      background: theme($colors, 'alto');
    }

    @include from('md') {
      margin-top: 0;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      right: 0;
      @include applyColumnWidth('width', 4, $desktop);
    }
  }

  .GridSliderMultipleLayout {
    .item {
      height: auto;
      cursor: zoom-in;
      grid-column: span 2;

      @include from('md') {
        grid-column: span 1;
      }
    }

    &.step-0 {
      grid-template-columns: repeat(2, 1fr);
      row-gap: theme($spacings, 20);
      column-gap: theme($spacings, 20);

      @include from('md') {
        @include applyColumnWidth('column-gap', 1, $desktop);
      }
    }

    &.step-1 {
      grid-template-columns: repeat(4, 1fr);
      row-gap: theme($spacings, 20);
      column-gap: theme($spacings, 20);
    }

    &.step-2 {
      grid-template-columns: repeat(8, 1fr);
      row-gap: theme($spacings, 10);
      column-gap: theme($spacings, 10);
    }
  }

  .content {
    align-items: center;
    width: vw(100);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    @include applyColumnWidth('padding-left', 1, $mobile);
    @include applyColumnWidth('padding-right', 1, $mobile);

    @include from('md') {
      @include applyColumnWidth('padding-left', 1, $desktop);
      @include applyColumnWidth('padding-right', 1, $desktop);
    }

    @include from('max-grid-width') {
      width: theme($max, 'width');
    }
  }

  .loadMore {
    margin-top: theme($spacings, 30);
  }

  .ratio {
    @include applyPercentRatios(math.div(315, 420));
  }
}
