@import 'styles/theme';

.FormEvent {
  .titleForm,
  .textForm {
    grid-column: 2 / -2;
    text-align: center;

    @include from('md') {
      grid-column: 8 / -8;
    }
  }

  .titleForm {
    line-height: 1;
  }

  .textForm {
    margin-top: theme($spacings, 10);
  }

  .form {
    grid-column: 3 / -3;
    margin-top: theme($spacings, 25);

    @include from('md') {
      grid-column: 8 / -8;
    }

    .input {
      width: 100%;

      input {
        text-align: center;
      }
    }

    .inputClassName {
      border: 0;

      &::placeholder {
        opacity: 0.5;
        text-align: center;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .legalsForm {
      margin-top: theme($spacings, 5);
      text-align: center;
    }
  }

  .checkbox {
    margin-top: theme($spacings, 20);
    grid-column: 3 / -3;

    @include from('md') {
      grid-column: 7 / -7;
    }
  }

  .result {
    margin-top: theme($spacings, 20);
    margin-bottom: theme($spacings, 20);
    grid-column: 4 / -4;
    text-align: center;
  }

  .submit {
    grid-column: 3 / -3;
    margin-top: theme($spacings, 15);

    @include from('md') {
      grid-column: 7 / -7;
    }
  }
}
