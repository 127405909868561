@import 'styles/theme';

.ImmersiveEditoTitleTextCta {
  margin-block: theme($spacings, 40);
  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .left {
    margin-bottom: theme($spacings, 20);

    &.isColumn {
      grid-column: 2 / -2;
      @include from('md') {
        grid-column: 2 / 7;
        margin-bottom: 0;
      }
    }

    &.isCentered {
      grid-column: 2 / -2;
      text-align: center;
      @include from('md') {
        grid-column: 10 / -10;
      }
    }
  }

  .right {
    &.isColumn {
      grid-column: 2 / -2;
      @include from('md') {
        grid-column: 13 / -3;
      }
    }

    &.isCentered {
      grid-column: 2 / -2;
      text-align: center;
      @include from('md') {
        grid-column: 10 / -10;
      }
    }
  }

  .title {
    & + .cta {
      margin-top: theme($spacings, 20);
    }
  }

  .text {
    & + .cta {
      margin-top: theme($spacings, 20);
    }
  }

  .cta {
    @include from('md') {
      &.isSquared {
        min-width: 200px;
      }
    }
  }
}
