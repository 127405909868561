@use 'sass:math';
@import 'styles/theme';

.StoriesGridImage {
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .item {
    grid-column: 2 / -2;

    @include from('md') {
      grid-column: 8 / -8;
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(600, 800));
  }
}
