@use 'sass:math';
@import 'styles/theme';

.CoverImage {
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  .imageContainer {
    overflow: hidden;
  }

  .title {
    position: relative;
    grid-column: 2 / -2;
    z-index: 1;
    margin-bottom: theme($spacings, 20);

    @include from('md') {
      margin-bottom: theme($spacings, 30);
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(330, 420));
    @include from('md') {
      @include applyPercentRatios(math.div(1320, 628));
    }
  }

  .banner {
    position: relative;
    grid-column: 1 / -1;

    .cta {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: theme($spacings, 40);

      @include from('md') {
        margin-bottom: theme($spacings, 60);
      }
    }
  }

  &.last-slice {
    margin-bottom: 0;
  }
}
