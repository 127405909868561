@use 'sass:math';
@import 'styles/theme';

.MasonryImageEdito {
  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 50);

  @include from('md') {
    margin-top: theme($spacings, 60);
    margin-bottom: theme($spacings, 60);
  }

  .image {
    height: auto;
    grid-row: 1 / 2;

    &.large {
      grid-column: 7 / -3;
    }

    &.small {
      grid-column: 3 / 15;

      @include applyColumns(
        (
          'propertyName': 'margin-top',
          'desktop': math.div(280, 60),
          'mobile': math.div(140, 15),
        )
      );
    }

    @include from('md') {
      &.large {
        grid-column: 3 / 11;
      }

      &.small {
        grid-column: 7 / 13;
      }
    }
  }

  .texts {
    align-self: center;
    grid-column: 2 / -2;
    margin-top: theme($spacings, 30);

    @include from('md') {
      grid-column: -4 / -12;
      margin-top: 0;
    }

    .title {
      text-transform: uppercase;
      overflow-wrap: break-word;
      hyphens: auto;

      + .text {
        margin-top: theme($spacings, 20);
      }
    }
  }

  &.reversed {
    @include from('md') {
      .image {
        &.large {
          grid-column: 15 / -3;
        }

        &.small {
          grid-column: 13 / -7;
        }
      }

      .texts {
        grid-column: 4 / 10;
      }
    }
  }

  .largeRatio {
    @include applyPercentRatios(math.div(480, 640));
  }

  .smallRatio {
    @include applyPercentRatios(math.div(360, 480));
  }
}
