@use 'sass:math';
@import 'styles/theme';

.StoriesSlider {
  width: 100%;
  overflow: hidden;
  margin-block: theme($spacings, 40);

  @include from('md') {
    margin-block: theme($spacings, 60);
  }

  &.titleSize-small {
    .title {
      text-align: left;
      margin-bottom: theme($spacings, 20);

      @include from('md') {
        margin-bottom: theme($spacings, 40);
      }
    }
  }

  .title {
    grid-column: 2 / -2;
    text-align: center;
    margin-bottom: theme($spacings, 30);

    @include from('md') {
      margin-bottom: theme($spacings, 50);
    }
  }

  .containerSlider {
    width: 100%;
    overflow: hidden;
  }

  .slider {
    width: 100%;

    .card {
      margin-right: theme($spacings, 10);

      @include from('md') {
        margin-right: theme($spacings, 25);
      }

      &.portrait {
        @include applyColumns(
          (
            'propertyName': 'width',
            'desktop': 5,
            'mobile': 12,
          )
        );
      }

      &.landscape {
        @include applyColumns(
          (
            'propertyName': 'width',
            'desktop': 7.5,
            'mobile': 18,
          )
        );
      }
    }
  }

  .portraitRatio {
    @include applyPercentRatios(math.div(300, 410));
  }

  .landscapeRatio {
    @include applyPercentRatios(math.div(455, 290));
  }
}
